@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a {
  text-decoration: none !important;
  color: inherit !important;
}

:root {
  --dark-color: #120A1FB2;
  --cyan-dark: #651e00;
  --primary-main: #E1BF7D;
  --primary-light: #F7E8D3;
  --primary-lighter: #fdf5e9;
}

#root, body, html {
  height: 100dvh;
  overflow: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #353535 rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer !important;
}
